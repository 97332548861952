import styled from 'styled-components';
import { useEffect } from 'react';
import { About, Description, Image, Hide } from '../styles';
import block from '../img/blocks_1.jpg';
import hovering from '../img/hovering.jpg';
import villa from '../img/villa8.jpg';
import perspektive from '../img/Perspektive 5.jpg';
import stone from '../img/Stoneskin.jpg';
import villa2 from '../img/villa2.jpg';
import elster from '../img/elster.jpg';
import spitz from '../img/Spitzdachhaus.jpg';
import Aos from 'aos';
import 'aos/dist/aos.css'





const Galerie = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, []);

    return (


        <div >
            <Wrapper>
                <Blocks data-aos="fade-zoom-in" data-aos-duration="3000"
                    src={block} alt="Blockhaus" />
                <Hovering data-aos="fade-zoom-in" data-aos-duration="3000"
                    src={hovering} alt="Hoveringhaus" />
                <Perspektive data-aos="fade-zoom-in" data-aos-duration="3000" src={perspektive} alt="Perspektive Haus" />

            </Wrapper>
            <Wrapper>
                <Villa data-aos="fade-zoom-in" data-aos-duration="3000" src={villa} alt="Villa for 8" />
                <Spitzdach data-aos="fade-zoom-in" data-aos-duration="3000" src={spitz} alt="Spitzdach Haus" />
                <Stone data-aos="fade-zoom-in" data-aos-duration="3000" src={stone} alt="Stoneskin Haus" />

            </Wrapper>
            <Wrapper>
                <Villa2 data-aos="fade-zoom-in" data-aos-duration="3000" src={villa2} alt="Villa for 8" />
                <Elster data-aos="fade-zoom-in" data-aos-duration="3000" src={elster} alt="Elster Haus" />
            </Wrapper>

        </div>

    );
};

// Styled Components
const Wrapper = styled.div`
display: flex;
width:100%;
@media (max-width: 1200px){
   flex-direction: column;
      
       
    }
`;
const Blocks = styled.img`
width: 40%;
height: 400px;
border-top: 3px  solid black;
border-bottom: 3px  solid black;
border-right: 3px  solid black;
object-fit: cover;
@media (max-width: 1200px){
   
    width: 100%;
    height: 40vh;
    border-bottom: 0px;
    border-right: 0px;
       
    }

`;

const Hovering = styled.img`
width: 40%;
height: 400px;
border-top: 3px  solid black;
border-bottom: 3px  solid black;
object-fit: cover;
@media (max-width: 1200px){
   
   width: 100%;
   height: 40vh;
   
      
   }

`;
const Villa = styled.img`
width: 35%;
height: 400px;
object-fit: cover;

border-bottom: 3px  solid black;
border-right: 3px  solid black;
@media (max-width: 1200px){
   
   width: 100%;
   height: 40vh;
   border-right: 0px;
      
   }

`;

const Perspektive = styled.img`
width: 20%;
height: 400px;
object-fit: cover;
border-left: 3px  solid black;
border-top: 3px  solid black;
border-bottom: 3px  solid black;
@media (max-width: 1200px){
   
   display:none;
      
   }
`;

const Spitzdach = styled.img`
width: 30%;
height: 400px;
object-fit: cover;
border-bottom: 3px  solid black;
@media (max-width: 1200px){
   
   width: 100%;
   height: 40vh;
      
   }
`;
const Stone = styled.img`
width: 35%;
height: 400px;
object-fit: cover;
border-bottom: 3px  solid black;
border-left: 3px  solid black;
@media (max-width: 1200px){
   
   width: 100%;
   height: 40vh;
   border-left: 0px;
      
   }

`;
const Villa2 = styled.img`
width: 50%;
height: 400px;
object-fit: cover;
border-bottom: 3px  solid black;
@media (max-width: 1200px){
   
   width: 100%;
   height: 40vh;
      
   }
`;

const Elster = styled.img`
width: 50%;
height: 400px;
object-fit: cover;
border-bottom: 3px  solid black;
border-left: 3px  solid black;
@media (max-width: 1200px){
   
   width: 100%;
   height: 40vh;
   border-left: 0px;
   }
`;














export default Galerie;
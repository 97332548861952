import { createGlobalStyle } from "styled-components";
import monti from '../fonts/monitorica.regular.ttf';
import monti2 from '../fonts/monitorica.bold.ttf';
import light from '../fonts/SairaSemiCondensed-Light.ttf';

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: "monti";
    src: url(${monti}) format("truetype")
  }
  @font-face {
    font-family: "monti2";
    src: url(${monti2}) format("truetype")
  }

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

html{
    background:#000;
    scroll-behavior: smooth;
    @media (max-width: 1700px){
        font-size:75%;
    }
    
}
body{
    font-family: 'monti';
    background: #fff;
    overflow-x: hidden;
    width: 80%;
    margin: 0 auto;   
    @media (min-width:1600px){
        width: 1600px;
    }
    @media (max-width:1399px){
        width: 100%;
    }
    
}

h2, h3{
    font-family: 'monti2';
}
button{
    font-weight: bold;
    font-size: 1.1.rem;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 3px solid #23d997;
    background: transparent;
   
    transition: all .5s ease;
    font-family: 'Inter', sans-serif;

    &:hover{
        background-color: #23d997;
      
    }
}

`

export default GlobalStyle
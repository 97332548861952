// import home1 from '../img/home1.png';
// import header from '../img/header.jpg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef } from 'react'
import video from '../img/WA_Introfilm.mp4';
//Styled Components 
import styled from 'styled-components';
import { About, Description, Image, Hide } from '../styles'
//Framer Motion

import { motion } from "framer-motion";
// import { titleAnim, fade, photoAnim } from "../animation";
// import Wave from './Wave';
// import light from '../fonts/SairaSemiCondensed-Light.ttf';
import pfeil from '../img/Pfeil.png';

const AboutSection = () => {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    // const titleAnim = {
    //     hidden: { opacity: 0 }, show: { opacity: 1, transition: { duration: 2 } }
    // }
    // const container = {
    //     hidden: { x: 100 }, show: { x: 0, transition: { duration: .75, ease: "easeOut" } }
    // }
    return (


        <About>


            <Image>

                <video
                    ref={videoRef}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                        width: "100%",
                        borderBottom: "3px  solid black",
                        borderTop: "3px  solid black",




                    }}
                >
                    <source src={video} type="video/mp4" />
                </video>

            </Image>




            <a href="#beschreibung">
                <span id="beschreibung">  </span>

                <Arrow>


                    <img src={pfeil} alt="pfeil" />
                </Arrow>

            </a>
            <Text>
                <Wrapper>
                    <div>
                        <h2>Ich bin freier Architekt</h2>
                        <p>
                            Schwerpunkt meiner Arbeit ist die Entwurfs-, Genehmigungs-
                            und Ausführungsplanung von kreativen Bauprojekten.
                    </p>
                        <p>
                            Durch ein flexibles und weitreichendes Netzwerk kompetenter Planer, Fachingenieuere und Handwerker kann ich Sie  durch alle Leistungsphasen eines Bauvorhabens begleiten.
                    </p>
                    </div>
                </Wrapper>

            </Text>
            <a href="#mobil">


                <Arrow2>
                    <span id="mobil"></span>

                    <img src={pfeil} alt="pfeil" />
                </Arrow2>

            </a>
            <span id="beschreibung"></span>

            {/* <Wave /> */}

        </About>
    );
};

// Styled Components
// const StyledA = styled.a`
// text-decoration: none;
// color: white;
// `;

const Img = styled(motion.img)` 
border-bottom: 3px  solid black;
border-top: 3px  solid black;
`

const Arrow = styled.div`
height:15vh;
 display:flex;
 justify-content:center;
 align-items: center;

 @media (max-width: 1599px){
    height:12vh;
}
@media (max-width: 599px){
  display:none;
   }

  img{

      width:5%;
      @media (max-width: 600px){
    /* display:none; */
    width:10%;
   
}
  }
  
  

`;
const Arrow2 = styled.div`
height:10vh;
 display:flex;
 justify-content:center;
 align-items: center;
 @media (min-width: 599px){
  display:none;
   }

 @media (max-width: 1599px){
    height:12vh;
}
  img{

      width:5%;
      @media (max-width: 600px){
    /* display:none; */
    width:10%;
   
}
  }
  

`;

const Text = styled.div`
width: 100%;
height: 300px;
@media (min-width: 600px){
    display:none;
}

h2{
    font-size: 2.2rem;
   margin-bottom: 20px;
}
p{
    font-size:1.5rem;
    margin-bottom: 20px;
    line-height:2rem;
}
`
const Wrapper = styled.div`

height:100%;
padding:3rem 8rem;
display: flex;
/* margin: 0 auto; */
align-items: center;
justify-content:center;
@media (max-width: 900px){
               padding:2rem 2rem;
               
    }

`;


export default AboutSection;
import styled from 'styled-components'
import { motion } from "framer-motion";

export const About = styled(motion.div)` 
height: 91vh;
margin-bottom: -7px;

/* padding: 5rem 10rem; */
@media (max-width: 1399px){
        
        height: 80vh;
        margin-bottom: 7px;
       
    }

@media (max-width: 1599px){
        display:block;
        height: 100%;
       
    }
    @media (min-width: 1600px){
        display:block;
        height: 100%;
       
    }
    a{
        text-decoration: none;
        color:#000;
    }
    span{
       
        display: flex;
        justify-content:center;
        align-items:center;
        h2{
            margin-top:50px;
            margin-bottom: -20px;
            @media (max-width: 1450px){
                display:none;
    }
        }
    }
   

`;

export const Description = styled.div`
/* min-height: 90vh; */
width: 100%;
border-top: 3px  solid black;
display:grid;
grid-template-columns: 1fr 1fr;
@media (max-width: 600px){
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.5fr 0.5fr
        
    }
/* @media (max-width: 1300px){
        display:flex;
        flex-direction: column;
       
    } */

.arrow{
    margin-left: 10px;
}


@media (max-width: 1300px){
       
        
    }
`;

export const Image = styled.div`

position:relative;
overflow: hidden;
z-index: 2;
img{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
`;

export const Hide = styled.div`
overflow: hidden;
`;
//Page components
import AboutSection from '../components/AboutSection'
import ScrollTop from '../components/ScrollTop'

//Animation
import { motion } from "framer-motion"
import { pageAnimation } from "../animation"
import Beschreibung from '../components/Beschreibung'
import Slogans from '../components/Slogans'
import Galerie from '../components/Galerie'
import Impressum from '../components/Impressum'
import Blog from '../components/Blog'

const AboutUs = () => {
    return (
        <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
            <AboutSection />
            <Beschreibung />
            <Slogans />
            <Blog />
            <Galerie />
            <Impressum />
            <ScrollTop />
        </motion.div>
    );
}

export default AboutUs;
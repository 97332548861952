import styled from 'styled-components';
import { useEffect, useState } from 'react'
import { About, Description, Image, Hide } from '../styles';
import links from '../img/beschreibung-links.jpg';
import rechts from '../img/beschreibung-rechts.jpg';
import portrait from '../img/portrait.jpg';
import flach from '../img/Flachdachhaus.jpg';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const Beschreibung = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, []);

    const [isChecked, setIsChecked] = useState(false);

    return (


        <Description  >


            <Label onClick={() => setIsChecked(!isChecked)}>
                <Check type="checkbox" onChange={(event) => setIsChecked(event.currentTarget.checked)}
                    checked={isChecked} />
                <Card className="card">
                    <Front>
                        <Img data-aos='flip-left' src={portrait} alt="Dirk Wartenberg" />
                        <h2>Vita  <FontAwesomeIcon className="arrow" icon={faArrowRight} /> </h2>


                    </Front>
                    <Back>
                        <div>

                            <h2>Dirk Wartenberg</h2>
                            <h3>geb. 05.09.1984</h3>
                            <h5>Verheiratet, 2 Kinder</h5>

                            <h3>Architekturstudium TU Dresden</h3>
                            <h3>2008/09 - Erio K. Hofmann Architekten, Salzburg</h3>
                            <h3>2009 - Gabriel Architekten, Leipzig</h3>
                            <h3>2010-21 - Auspurg Borchowitz + Partner, Leipzig</h3>

                            <h4>zurück <FontAwesomeIcon className="arrow" icon={faArrowRight} /> </h4>
                        </div>

                    </Back>
                </Card>
            </Label>

            <Container >


                <Text >
                    <Wrapper>
                        <div>
                            <h2>Ich bin freier Architekt</h2>
                            <p>
                                Schwerpunkt meiner Arbeit ist die Entwurfs-, Genehmigungs-
                                und Ausführungsplanung von kreativen Bauprojekten.
                    </p>
                            <p>
                                Durch ein flexibles und weitreichendes Netzwerk kompetenter Planer, Fachingenieuer und Handwerker, kann ich Sie  durch alle Leistungsphasen eines Bauvorhabens begleiten.
                    </p>
                        </div>
                    </Wrapper>
                </Text>
                <div>
                    <Label onClick={() => setIsChecked(!isChecked)}>
                        <Check type="checkbox" onChange={(event) => setIsChecked(event.currentTarget.checked)}
                            checked={isChecked} />
                        <Card2 className="card">
                            <Front2>
                                <Img2 src={flach} alt="inneneinrichtung" />

                            </Front2>
                            <Back2>

                                <Img2 src={rechts} alt="inneneinrichtung" />
                            </Back2>
                        </Card2>
                    </Label>
                </div>


            </Container>




        </Description>
    );
};

// Styled Components


const Label = styled.div`
perspective: 1000px;
	transform-style: preserve-3d;
	cursor: pointer;
    & :checked +.card{
        transform: rotateY(175deg);
       
    }
`;
const Check = styled.input`
display:none;

&:checked + .card{
    transform: rotateY(180deg);
}
`;
const Card = styled.div`
position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: all 0.5s ease-in-out;
	z-index: 1;
`;
const Front = styled.div`
position: absolute;
	width: 100%;
	height: 100%;
	text-align: center;
	background: #fff;
	backface-visibility: hidden;
    h2{
        color:#fff;
        position: absolute;
        top: 5%;
        right: 10%;
    }
   
`;

const Back = styled.div`
position: absolute;
display: flex;
justify-content:center;
align-items: center;
flex-direction: column;
	width: 100%;
	height: 100%;
	text-align: center;
	background: #000;
	backface-visibility: hidden;
    transform: rotateY(180deg);
    border-bottom: 3px solid #000;
    border-right: 3px solid #000;
    h2{
        font-size: 35px;
	color: #fff;
	margin: 50px 0;
    }
    h3{
        font-size: 22px;
	color: #fff;
	margin: 12px 0 12px 0;
    }
    h5{
        font-size: 22px;
	color: #fff;
	margin: 12px 0 12px 0;
    margin-bottom: 3rem;
    }
    h4{
        font-size: 22px;
        font-weight:lighter;
	color: #fff;
    margin-top:20px;
    }
   
`;

const Img = styled.img`
width: 100%;
height:900px;
object-fit: cover;
border-bottom: 3px  solid black;
border-right: 3px  solid black;
@media (max-width: 900px){
    height: 700px;
               
    }


`;

const Card2 = styled.div`
position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: all 0.5s ease-in-out;
	z-index: 1;
`;
const Front2 = styled.div`
position: absolute;
	width: 100%;
	height: 100%;
	text-align: center;
	background: #fff;
	backface-visibility: hidden;
   
   
`;

const Back2 = styled.div`
position: absolute;
	width: 100%;
	height: 100%;
	
	
	backface-visibility: hidden;
    transform: rotateY(-180deg);
    /* border-bottom: 3px solid #000;
    border-right: 3px solid #000; */
    
`;
const Img2 = styled.img`
width: 100%;
height:500px;
border-bottom: 3px  solid black;
object-fit: cover;
object-position: 50% 25%;
@media (min-width: 1599px){
    object-fit: cover;
    
object-position: 50% 46%;
}
@media (max-width: 900px){
    height: 400px;
               
    }
`;

const Container = styled.div`
width: 100%;
height:900px;
display: flex;
flex-direction: column;
@media (max-width: 900px){
    height: 700px;
               
    }
`;

const Text = styled.div`
width: 100%;
height: 400px;
border-bottom: 3px  solid black;
@media (max-width: 600px){
    display:none;
}
@media (max-width: 900px){
    height: 300px;
               
    }
h2{
    font-size: 2.2rem;
   margin-bottom: 20px;
}
p{
    font-size:1.5rem;
    margin-bottom: 20px;
    line-height:2rem;
}
`
const Wrapper = styled.div`

height:100%;
padding:3rem 8rem;
display: flex;
/* margin: 0 auto; */
align-items: center;
justify-content:center;
@media (max-width: 900px){
               padding:2rem 2rem;
               
    }

`;



export default Beschreibung;
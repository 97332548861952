import styled from 'styled-components';
import { About, Description, Image, Hide } from '../styles';
import insta from '../img/instagram.png';
import twitter from '../img/twitter.png';
import linkedin from '../img/linkedin.png';
import xing from '../img/xing.png';
import face from '../img/facebook.png';
import {

    Link
} from "react-router-dom";


const Impressum = () => {


    return (


        <Wrapper>
            <div>
                <Absatz>
                    <Name>Wartenberg Architektur</Name>
                    <Anschrift>Dirk Wartenberg</Anschrift>
                    <Anschrift>Waldstrasse 51</Anschrift>
                    <Anschrift>04683 Naunhof</Anschrift>
                </Absatz>
                <article>
                    <Mail href="mailto:mail@wartenberg-architektur.com">mail@wartenberg-architektur.com</Mail>
                    <Anschrift>mobil 0151 111 588 99</Anschrift>
                </article>
                <Links>
                    <Link style={{ color: "#000", textDecoration: "none", fontSize: "1.5rem" }} to="/Impressum">Impressum</Link> <Strich>|</Strich>
                    <Link style={{ color: "#000", textDecoration: "none", fontSize: "1.5rem" }} to="/Datenschutz">Datenschutz</Link>
                </Links>
            </div>
            <Logos>
                <Social href="https://www.instagram.com/wartenbergarchitektur/" target="_blank"><Logo src={insta} alt="instagram logo" /></Social>
                <Social href="https://www.facebook.com/Wartenberg-Architektur-105415445341486" target="_blank"><Logo src={face} alt="facebook logo" /></Social>
                <Social href="https://www.linkedin.com/in/dirk-wartenberg-813730216/?originalSubdomain=de" target="_blank"><Logo src={linkedin} alt="linkedin logo" /></Social>
                <Social href="https://www.xing.com/profile/Dirk_Wartenberg2" target="_blank"><Logo src={xing} alt="xing logo" /></Social>




            </Logos>

        </Wrapper>
    );
};

// Styled Components
const Wrapper = styled.div`
width: 80%;
display: flex;
justify-content: space-between;
margin: 0rem auto;
padding: 5rem 0 3rem;
@media (max-width: 450px){

flex-direction: column;
align-items: center;


}
article{
    @media (max-width: 450px){
display: flex;
flex-direction: column;
align-items: center;


}
}
`;

const Name = styled.h2`
font-size: 2rem;
`

const Mail = styled.a`
text-decoration: none;
color: black;
display: block;
font-size: 1.5rem;
`;
const Social = styled.a`
display:block;
margin: 0rem;
`;

const Absatz = styled.article`
margin-bottom: 1.5rem;
@media (max-width: 450px){
display: flex;
flex-direction: column;
align-items: center;


}
`

const Anschrift = styled.p`
font-size: 1.5rem;
line-height:2rem;
`;


const Logos = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
@media (max-width: 450px){

display: flex;
justify-content:center;
margin: 2rem 0 1rem;


}

`;

const Logo = styled.img`
width:4rem;
height:4rem;
margin-left: 1.5rem;

`;
const Links = styled.div`
width: 150%;
@media (max-width: 450px){
    display: flex;

justify-content:center;
width:100%;

}
`;
const Strich = styled.span`

font-size:1.5rem;
margin: 0 1rem;
`;









export default Impressum;

import { motion } from "framer-motion"
import { pageAnimation } from "../animation"
import styled from 'styled-components';
import Impressum from "../components/Impressum"
import Zurueck from "../components/Zurück";
import SrollTop from "../components/ScrollTop";



const Datenschutz = () => {
    return (

        <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
            <Daten>
                <h1>Datenschutzerklärung</h1>
                <h2>Einleitung und Überblick</h2>
                <p>Wir haben diese Datenschutzerklärung (Fassung 18.12.2021-321901778) verfasst, um Ihnen
                gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und anwendbaren
                nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als
                Verantwortliche – und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) –
                verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben.
                Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.
Kurz gesagt: Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
                <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische
                Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so
                einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist,
                werden technische Begriffe leserfreundlich erklärt, Links zu weiterführenden
                Informationen geboten und Grafiken zum Einsatz gebracht. Wir informieren damit in klarer
                und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann
                personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage
                gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-
                technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um
                Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und
                informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht
                kannten.
                Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum
                genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich
weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>

                <h2>Anwendungsbereich</h2>
                <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten
                personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte
                Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir
                Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse
                und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt
                dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es
                online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:
                 alle Onlineauftritte (Websites, Onlineshops), die wir betreiben
                 Social Media Auftritte und E-Mail-Kommunikation
                 mobile Apps für Smartphones und andere Geräte
                Kurz gesagt: Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene
                Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir
                außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie
gegebenenfalls gesondert informieren.</p>
                <h2>Rechtsgrundlagen</h2>
                <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den
                rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-
                Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.
                Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES

                EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese
                Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex,
                dem Zugang zum EU-Recht, unter https://eur-lex.europa.eu/legal-
                content/DE/TXT/?uri=celex%3A32016R0679 nachlesen.Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:
                1. Einwilligung (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung
                gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die
                Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
                2. Vertrag (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche
                Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum
                Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab
                personenbezogene Informationen.
                3. Rechtliche Verpflichtung (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer
                rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind
                wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese
                enthalten in der Regel personenbezogene Daten.
                4. Berechtigte Interessen (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter
                Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung
                personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten,
                um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese
                Verarbeitung ist somit ein berechtigtes Interesse.
                Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und
                Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in
                der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird
                diese an der entsprechenden Stelle ausgewiesen.
                Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:
                 In Österreich ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der
                Verarbeitung personenbezogener Daten (Datenschutzgesetz), kurz DSG.
                 In Deutschland gilt das Bundesdatenschutzgesetz, kurz BDSG.
                Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir
Sie in den folgenden Abschnitten darüber.</p>
                <h2>Kontaktdaten des Verantwortlichen</h2>
                <p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der
                verantwortlichen Person bzw. Stelle:
                Wartenberg Architektur
                Waldstraße 51
                04683 Naunhof
                E-Mail: mail@wartenberg-architektur.com
Telefon: 0151 11158899</p>
                <h2>Speicherdauer</h2>
                <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung
                unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium
                bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die
                Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu
                verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern,
                zum Beispiel zu Zwecken der Buchführung.
                Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung
                widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung
                besteht, gelöscht.
                Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten,
sofern wir weitere Informationen dazu haben.</p>
                <h2>Rechte laut Datenschutz-Grundverordnung</h2>
                <p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und
                transparenten Verarbeitung von Daten kommt:
                 Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von
                Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten
                zu erhalten und die folgenden Informationen zu erfahren:
                o zu welchem Zweck wir die Verarbeitung durchführen;
                o die Kategorien, also die Arten von Daten, die verarbeitet werden;
                o wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden,
                wie die Sicherheit garantiert werden kann;
                o wie lange die Daten gespeichert werden;
                o das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der
                Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;
                o dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen
                Behörden finden Sie weiter unten);
                o die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;
                o ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet
                werden, um zu einem persönlichen Profil von Ihnen zu gelangen.
                 Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was
                bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.
                 Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
                Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten
                verlangen dürfen.
                 Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was
                bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.
                 Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet,
                dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung
                stellen.
                 Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung
                eine Änderung der Verarbeitung mit sich bringt.
                o Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches
                Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f
                (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung
                Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem
                Widerspruch rechtlich nachkommen können.

                o Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
                jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen
                Ihre Daten danach nicht mehr für Direktmarketing verwenden.
                o Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit
                gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten
                danach nicht mehr für Profiling verwenden.

                 Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
                ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling)
                beruhenden Entscheidung unterworfen zu werden.
                Kurz gesagt: Sie haben Rechte – zögern Sie nicht, die oben gelistete verantwortliche Stelle
                bei uns zu kontaktieren!
                Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt
                oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind,
                können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die
                Datenschutzbehörde, deren Website Sie unter https://www.dsb.gv.at/ finden. In Deutschland
                gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen
                können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
                (BfDI) wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde
zuständig:</p>
                <h2>Sachsen Datenschutzbehörde</h2>
                <p>Landesbeauftragter für Datenschutz: Andreas Schurig
                Adresse: Devrientstraße 5, 01067 Dresden
                Telefonnr.: 03 51 / 85471-101
                E-Mail-Adresse: saechsdsb@slt.sachsen.de
Website: https://www.saechsdsb.de/</p>
                <h2>Kommunikation</h2>
                <p>Kommunikation Zusammenfassung
                Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren
                Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene
                Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart
                Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.
                Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften
                ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b
DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
                <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular
                kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.
                Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit
                zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während ebensolange
gespeichert bzw. so lange es das Gesetz vorschreibt.</p>
                <h3>Betroffene Personen</h3>
                <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten
Kommunikationswege den Kontakt zu uns suchen.</p>
                <h3>Telefon</h3>
                <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim
                eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können
                Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur
                Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der
Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
                <h3>E-Mail</h3>
                <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem
                jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert und es kommt zur
                Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der
Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
                <h3>Online Formulare</h3>
                <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem
                Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet.
                Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche
Vorgaben erlauben.</p>
                <h3>Rechtsgrundlagen</h3>
                <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:
                 Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten
                zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;
                 Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung
                eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem
                Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B.
                die Vorbereitung eines Angebots, verarbeiten;
                 Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und
                geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind
                gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und
Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</p>
                <h2>Cookies</h2>
                <p>Cookies Zusammenfassung
                Betroffene: Besucher der Website
                Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw
                beim Hersteller der Software, der das Cookie setzt.
                Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden

                Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.
                Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren
                variieren
                ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO
(Berechtigte Interessen)</p>
                <h3>Was sind Cookies?</h3>
                <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.
                Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die
                folgende Datenschutzerklärung besser verstehen.
                Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser
                sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die
                meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man
                Cookies.
                Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle
                Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch
                andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die
                von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden
                automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie
                besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen
                zusätzlich ein oder mehrere Attribute angegeben werden.
                Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder
                persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr
                Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß
                unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In
                einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox
                sind alle Cookies in einer einzigen Datei gespeichert.
                Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B.
                Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom
                Server ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere Seite
                angefordert wird.

                Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies
                werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites
                (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie
                andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis
                hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren,
                Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs
                zugreifen.
                So können zum Beispiel Cookie-Daten aussehen:
                Name: _ga
                Wert: GA1.2.1326744211.152321901778-9
                Verwendungszweck: Unterscheidung der Websitebesucher
                Ablaufdatum: nach 2 Jahren
                Diese Mindestgrößen sollte ein Browser unterstützen können:
                 Mindestens 4096 Bytes pro Cookie
                 Mindestens 50 Cookies pro Domain
 Mindestens 3000 Cookies insgesamt</p>
                <h3>Welche Arten von Cookies gibt es?</h3>
                <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten
                Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An
                dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
Man kann 4 Arten von Cookies unterscheiden:</p>
                <h3>Unerlässliche Cookies</h3>
                <p>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum
                Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf
                anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der
Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
                <h3>Zweckmäßige Cookies</h3>
                <p>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige
                Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das
Verhalten der Website bei verschiedenen Browsern gemessen.</p>
                <h3>Zielorientierte Cookies</h3>
                <p>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden
eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
                <h3>Werbe-Cookies</h3>
                <p>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User
                individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig
                sein.
                Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser
                Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem
                Cookie gespeichert.
                Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht
                scheuen, empfehlen wir https://tools.ietf.org/html/rfc6265, dem Request for Comments der
Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.</p>
                <h3>Zweck der Verarbeitung über Cookies</h3>
                <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie
weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</p>
                <h3>Welche Daten werden verarbeitet?</h3>
                <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies
                gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen
                der folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten
informieren.</p>
                <h3>Speicherdauer von Cookies</h3>
                <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche
                Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf
                einem Computer gespeichert bleiben.
                Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser
                sämtliche Cookies jederzeit manuell löschen (siehe auch unten “Widerspruchsrecht”). Ferner
                werden Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer
Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.</p>
                <h3>Widerspruchsrecht – wie kann ich Cookies löschen?</h3>
                <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem
                Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit
                Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie
                Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.
                Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn
                Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-
                Einstellungen finden:
                Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                Safari: Verwalten von Cookies und Websitedaten mit Safari
                Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt
                haben
                Internet Explorer: Löschen und Verwalten von Cookies

                Microsoft Edge: Löschen und Verwalten von Cookies
                Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten,
                dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem
                einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist
                je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem
                Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines
Chrome Browsers.</p>
                <h3>Rechtsgrundlage</h3>
                <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das
                Speichern von Cookies eine Einwilligung (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen
                verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen
                auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs.
                3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien
                nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie
                weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
                Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen
                berechtigte Interessen (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen
                wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme
                Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.
                Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im
                Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.
                In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert,
sofern eingesetzte Software Cookies verwendet.</p>
                <h2>Webhosting Einleitung</h2>
                <p>Webhosting Zusammenfassung
                Betroffene: Besucher der Website
                Zweck: professionelles Hosting der Website und Absicherung des Betriebs
                Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und
                weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten
                Webhosting Provider.
                Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen
⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
                <h3>Was ist Webhosting?</h3>
                <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen – auch
                personenbezogene Daten – automatisch erstellt und gespeichert, so auch auf dieser Website.
                Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit
                Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von

                der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain
                meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.
                Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein
                Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim
                Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari.
                Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der
                Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte
                und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den
                Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine
                verlässliche und fehlerfreie Speicherung der Daten von Websites.
                Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop,
                Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer
                Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten,
                andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen
Betrieb zu gewährleisten.</p>
                <p>Zur Veranschaulichung:</p>
                <h3>Warum verarbeiten wir personenbezogene Daten?</h3>
                <p>Die Zwecke der Datenverarbeitung sind:
                1. Professionelles Hosting der Website und Absicherung des Betriebs
                2. zur Aufrechterhaltung der Betriebs- und IT-Sicherheit
                3. Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und
ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</p>
                <h3>Welche Daten werden verarbeitet?</h3>
                <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist
                der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie
                 die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B.
                https://www.beispielwebsite.de/beispielunterseite.html?tid=321901778)
                 Browser und Browserversion (z. B. Chrome 87)
                 das verwendete Betriebssystem (z. B. Windows 10)
                 die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
                https://www.beispielquellsite.de/vondabinichgekommen.html/)
                 den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z.
                B. COMPUTERNAME und 194.23.43.121)
                 Datum und Uhrzeit
 in Dateien, den sogenannten Webserver-Logfiles</p>
                <h3>Wie lange werden Daten gespeichert?</h3>
                <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach
                automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen,
                dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen
                werden.
                Kurz gesagt: Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf
                speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht
ohne Zustimmung weiter!</p>
                <h3>Rechtsgrundlage</h3>
                <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting
                ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die
                Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das
                Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und
                Forderungen hieraus gegebenenfalls verfolgen zu können.
                Zwischen uns und dem Hostingprovider besteht in der Regel ein Vertrag über die
                Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz
gewährleistet und Datensicherheit garantiert.</p>
                <h2>webgo Datenschutzerklärung</h2>
                <p>Wir nutzen für unsere Website webgo, unter anderem ein Webhosting-Anbieter.
                Dienstanbieter ist das deutsche Unternehmen webgo GmbH, Heidenkampsweg 81, 20097
                Hamburg, Deutschland. Mehr über die Daten, die durch die Verwendung von webgo
                verarbeitet werden, erfahren Sie in der Datenschutzerklärung auf
                https://www.webgo.de/datenschutz/ .
                Alle Texte sind urheberrechtlich geschützt.
Quelle: Erstellt mit dem Datenschutz Generator von AdSimple</p>
            </Daten>
            <Impressum />
            <Zurueck />
            <SrollTop />
        </motion.div>
    );
};

const Daten = styled.section`
width: 80%;
margin: 0 auto;
h1{
    font-size:3rem;
    font-weight: bold;
    margin:2rem 0;
}
h2{
    font-size:2rem;
    margin: 1.5rem 0;
}
h3{
    font-size:1.5rem;
    margin: 1rem 0;
}
p{
    font-size:1.3rem;
    margin: 1rem 0;
    text-align: justify;
    line-height:1.8rem;
}
`;

export default Datenschutz;
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { useLocation } from 'react-router-dom';
import logo from '../img/logo.png';

const Nav = () => {
    const { pathname } = useLocation()
    return (
        <SyledNav>
            <img src={logo} alt="logo wartenberg" />

        </SyledNav>
    );
};

const SyledNav = styled.nav`
min-height: 10vh;
display: flex;
margin: auto;
align-items: center;
position: sticky;
top: 0;
justify-content: space-between;
align-items: center;
padding: 1rem 10rem;
background: #fff;
/* opacity: .5; */
/* position: sticky; */
top:0;
z-index: 10;
img{
    width:35%;
    margin: 0 auto;
    @media (max-width: 600px){

width: 140%;
margin:0;



}
}

@media (max-width: 1599px){
    min-height: 1vh;
       flex-direction: column;
      margin:0;
      
       
    }


@media (max-width: 1300px){
    min-height: 1vh;
       flex-direction: column;
      margin:0;
       #logo{
           display:inline-block;
           margin: 2rem;
       }
       
    }
    @media (max-width: 600px){

        min-height: 5vh;
    
       
    }
`;





export default Nav;
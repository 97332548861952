import styled from 'styled-components';
import Aos from 'aos';
import 'aos/dist/aos.css'
import logo from '../img/WA_Logo_blog.png';

const Blog = () => {
    return (
        <BlogSection>
            <div data-aos='zoom-in'>
            <h3>Referenzen</h3>
            <p>Unten sehen Sie einige Referenzen aus meiner Tätigkeit bei Auspurg Borchowitz + Partner.

            Eine Übersicht über meine aktuellen Projekte finden Sie hier:
           

            </p>
            
            </div>
           
                <a href="http://wartenberg-architektur.de/">
            <BlogImage src={logo} alt="logo Blog" />
            </a>
            
         
            
        </BlogSection>
    );
}


const BlogSection = styled.section`

display:flex;
flex-direction: column;
justify-content:center;
align-items: center;
border-top: 3px  solid black;
div{
    width:75%;
    padding-top:4rem;
}
h3{font-size:2rem;}
p{
    font-size:1.5rem;
    width:100%;
    line-height:2rem;
    margin:0rem;}

    a{
      display:flex;
        
    }
`;



const BlogImage = styled.img`
width: 5%;
margin: 1rem auto 3rem;
cursor: pointer;
@media (max-width: 600px){
    width: 20%;
    padding-top:3rem;
}
`;

export default Blog;
import { motion } from "framer-motion"
import { pageAnimation } from "../animation"
import styled from 'styled-components';
import Impressum from "../components/Impressum"
import Zurueck from "../components/Zurück";
import SrollTop from "../components/ScrollTop";


const ImpressumPage = () => {
    return (

        <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
            <Daten>
                <h1>Impressum</h1>
                <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63
Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>
                <p>Wartenberg Architektur<br />
                Waldstraße 51,<br />
                04683 Naunhof,
Deutschland</p>
                <p>
                    <b>Tel.: </b> 0151 11158899<br />
                    <b>E-Mail:</b> mail@wartenberg-architektur.com
</p>
                <p>
                    <b>Berufsbezeichnung:</b>  Freier Architekt
</p>
                <p>
                    <b>
                        Berufshaftpflichtversicherung</b><br />
                    VHV Allgemeine Versicherung AG<br />
                    VHV-Platz 1<br />
                    30177 Hannover<br />
                    Räumliche Geltung: Deutschland
</p>
                <p>Kontaktdaten des Verantwortlichen für Datenschutz
                Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der
                verantwortlichen Person bzw. Stelle:<br />
                Wartenberg Architektur<br />
                Waldstraße 51<br />
                04683 Naunhof<br />
                E-Mail-Adresse: mail@wartenberg-architektur.com<br />
Telefon: 0151 11158899</p>
                <h2>EU-Streitschlichtung</h2>
                <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-
                Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform)
                informieren.
                Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der
                Europäischen Kommission unter http://ec.europa.eu/odr?tid=321901778 zu richten. Die dafür
notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
                <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                <h2>Haftung für Inhalte dieser Website</h2>
                <p>Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns korrekte und
                aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit
                aller Inhalte auf dieser Website übernehmen, speziell für jene, die seitens Dritter bereitgestellt
                wurden. Als Diensteanbieter sind wir nicht verpflichtet, die von ihnen übermittelten oder

                gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
rechtswidrige Tätigkeit hinweisen.</p>
                <p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung
                von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder
                behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit davon
unberührt.</p>
                <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend
                zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die
Kontaktdaten im Impressum.</p>
                <h2>Haftung für Links auf dieser Website</h2>
                <p>Unsere Website enthält Links zu anderen Websites für deren Inhalt wir nicht verantwortlich
                sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis
                rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht
                aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten
bekannt werden.</p>
                <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu
kontaktieren. Sie finden die Kontaktdaten im Impressum.</p>
                <h2>Urheberrechtshinweis</h2>
                <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht.
                Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder
                verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig,
werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
                <p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie
uns zu kontaktieren.</p>
                <h2>Bildernachweis</h2>
                <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
                <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
                <p>Fotograf Mustermann</p>
                <p>Alle Texte sind urheberrechtlich geschützt.</p>
                <p>Quelle: Erstellt mit dem Impressum Generator von AdSimple</p>

            </Daten>
            <Impressum />
            <Zurueck />
            <SrollTop />
        </motion.div>
    );
}

const Daten = styled.section`
width: 80%;
margin: 0 auto;
h1{
    font-size:3rem;
    font-weight: bold;
    margin:2rem 0;
}
h2{
    font-size:2rem;
    margin: 1.5rem 0;
}
h3{
    font-size:1.5rem;
    margin: 1rem 0;
}
p{
    font-size:1.3rem;
    margin: 1rem 0;
    text-align: justify;
    line-height:1.8rem;
}
`;

export default ImpressumPage;
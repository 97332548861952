import styled from 'styled-components';
import { About, Description, Image, Hide } from '../styles';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'


const Slogans = () => {
    useEffect(() => {
        Aos.init({ duration: 1500, delay: 300 })
    }, []);

    return (



        <Wrapper >

            <h2 >Wartenberg Architektur ist</h2>
            <div data-aos='zoom-in'>
                <h3>ganzheitlich.</h3>
                <p>
                    Kein Haus besteht nur aus Steinen. Jedes Projekt ist auf seine Weise in einen besonderen sozialen und natürlichen Kontext eingebettet. Jeder Nutzer und jedes Umfeld verdient eine ganzheitliche Betrachtung. Daher biete ich auch Sonderleistungen im Bereich Innenarchitektur, Außenanlagenplanung, Visualisierung und Architekturmarketing an.
            </p>
            </div>
            <div data-aos='zoom-in'>
                <h3>menschlich.</h3>
                <p>
                    Ich interessiere mich für Sie. Von der ersten Idee, über die baurechtliche Durchsetzung bis zur detaillierten Beratung in der Umsetzungsphase - Sie finden einen engagierten Partner, der sich mit ihrem Projekt identifiziert - und immer persönlich ansprechbar bleibt.
            </p>
            </div>
            <div data-aos='zoom-in'>
                <h3>erfolgreich.</h3>
                <p>
                    18 Jahre täglich Architektur. 13 Jahre Berufserfahrung als Architekt und Projektleiter. Maßgebliche Mitwirkung an über 50 individuellen Einfamilienvillen und 30 exklusiven Mehrfamilienhäusern in 15 Städten. Projektierung von Kultur-, Hotel-, Gewerbe- und Sportbauten. Bauprojekte lösen keine Angst, sondern Freude aus - wenn man weiß, was man tut.
            </p>
            </div>
        </Wrapper>

    );
};

// Styled Components
const Wrapper = styled.div`
z-index: 2;
width: 75%;
margin: 6rem auto;
@media (max-width: 600px){
    margin: -50rem auto 6rem;
    }
h2{
    font-size: 2rem;
   margin-bottom: 4rem;
}
h3{
    font-size: 2rem;
}
p{
    font-size:1.5rem;
    margin-bottom: 30px;
    line-height:2rem;
}



`;










export default Slogans;
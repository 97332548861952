//Router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


//Global Style
import GlobalStyle from './components/GlobalStyle';
import Nav from './components/Nav';
//Import Pages
import AboutUs from './pages/AboutUs';

// import ScrollTop from './components/ScrollTop'
//Router


//Animation
import { AnimatePresence } from "framer-motion"
import Impressum from "./pages/Impressum-Page";
import Datenschutz from "./pages/Datenschutz";




function App() {

  //console.log('hallo', location.pathname)
  return (
    <div className="App">
      <Router>
        <GlobalStyle />
        <Nav />
        <Switch>
          <Route exact path="/">
            <AnimatePresence exitBeforeEnter>
              <AboutUs />
            </AnimatePresence>
          </Route>
          <Route path="/impressum">
            <Impressum />
          </Route>
          <Route path="/datenschutz">
            <Datenschutz />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

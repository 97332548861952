import styled from 'styled-components';

import {

    Link
} from "react-router-dom";

const Zurueck = () => {
    return (
        <Text>
            <Link style={{ color: "#000", textDecoration: "none", fontSize: "2rem" }} to="/"> Zurück zur Startseite</Link>
        </Text>
    );
}

export default Zurueck;
const Text = styled.div`
display: flex;
justify-content:center;
margin-top:-3rem;
padding-bottom: 5rem

`;